import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import {
    salesGuestValidOrderResultVar,
} from '../../layouts/guest-layout/guest-layout-state';
import { SalesLayout } from '../../layouts/sales-layout';
import { ReturnViewPageContent } from './return-view-page-content';

const ReturnViewGuestPage: React.FC = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const salesGuestValidOrderResult = useReactiveVar(salesGuestValidOrderResultVar);

    const returnViewPageComponent: JSX.Element = isLoaded ? (<ReturnViewPageContent />) : (<></>);

    useEffect(() => {
        if (!salesGuestValidOrderResult) {
            return;
        }

        const { result, redirectUrl } = salesGuestValidOrderResult;
        const isRedirect = !result && !!redirectUrl.length;

        if (isRedirect) {
            navigateToUrl({
                navigate,
                url: redirectUrl,
            });
            return;
        }

        setIsLoaded(true);
    }, [salesGuestValidOrderResult]);

    return (
        <SalesLayout
            pageKey="return-view-guest"
            pageTitle={t('Your Return')}
            pageContent={returnViewPageComponent}
            isGuestPage
            loadCountries
            loadCart={false}
            loadOrder={false}
            splitOrderItems={false}
            loadWidgets={false}
        />
    );
};

export { ReturnViewGuestPage as default };
